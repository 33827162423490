import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { CardContent } from "@mui/material";
import {
  EnvelopeSimple,
  Infinity,
  FlagBanner,
  EnvelopeSimpleOpen,
  FlagBannerFold,
} from "@phosphor-icons/react";
import { hasValue } from "../../../utils/commonFns";
import { format } from "date-fns";
import "react-vertical-timeline-component/style.min.css";
import { getAsssessmentData } from "../../../services/tprmSupportService";
import { useParams } from "react-router-dom";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";

export default function AssessmentDetails() {
  const queryParams = useParams();
  const assessmentId = queryParams["assessmentId"];

  const [assessmentData, setAssessmentData] = useState({});

  useEffect(() => {
    const fetchAssessmentData = async () => {
      const { result } = await getAsssessmentData(assessmentId);
      setAssessmentData(result);
    };

    fetchAssessmentData();
  }, []);

  const items = [
    {
      title: "Request Created",
      subTitle: hasValue(assessmentData?.createdOn)
        ? format(new Date(assessmentData?.createdOn), "PPP p")
        : "--",
      completed: hasValue(assessmentData?.createdOn) ? true : false,
      reIcon: <EnvelopeSimple size={18} />,
      iconBackground: "#4CAF4F",
    },
    {
      title: "In progress",
      subTitle: hasValue(assessmentData?.inProgressOn)
        ? format(new Date(assessmentData?.inProgressOn), "PPP p")
        : "--",
      completed: hasValue(assessmentData?.inProgressOn) ? true : false,
      reIcon: <Infinity size={18} />,
      iconBackground: "#F44334",
    },
    {
      title: "Completed",
      subTitle: hasValue(assessmentData?.completedOn)
        ? format(new Date(assessmentData?.completedOn), "PPP p")
        : "--",
      completed: hasValue(assessmentData?.completedOn) ? true : false,
      reIcon: <FlagBanner />,
      iconBackground: "#1B72E8",
    },
  ];

  if (hasValue(assessmentData?.reOpenedOn)) {
    items.push(
      ...[
        {
          title: "Re opened",
          subTitle: hasValue(assessmentData?.reOpenedOn)
            ? format(new Date(assessmentData?.reOpenedOn), "PPP p")
            : "--",
          completed: hasValue(assessmentData?.reOpenedOn) ? true : false,
          reIcon: <EnvelopeSimpleOpen size={18} />,
          iconBackground: "rgb(251,140,0)",
        },
        {
          title: "Completed",
          subTitle: hasValue(assessmentData?.reCompletedOn)
            ? format(new Date(assessmentData?.reCompletedOn), "PPP p")
            : "--",
          completed: hasValue(assessmentData?.reCompletedOn) ? true : false,
          reIcon: <FlagBannerFold size={18} />,
          iconBackground: "rgb(233,30,98)",
        },
      ]
    );
  }

  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition
          component="div"
          className="TabsAnimation"
          appear={true}
          timeout={0}
          enter={false}
          exit={false}
        >
          <div>
            <Card sx={{ height: "100%" }}>
              <CardHeader title="Assessment Timeline" />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid
                    xs={4}
                    alignContent={"flex-start"}
                    justifyContent={"flex-start"}
                  >
                    <Timeline position="alternate">
                      {items.map((item, index) => {
                        return (
                          <TimelineItem>
                            <TimelineOppositeContent
                              sx={{ m: "11.5px 0" }}
                              align={index % 2 === 0 ? "right" : "left"}
                              variant="body2"
                              color="text.secondary"
                            >
                              {item.subTitle}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot
                                sx={{
                                  background: item.iconBackground,
                                  color: "#fff",
                                  boxShadow:
                                    "0 0 0 4px #dcb9cc, inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05)",
                                }}
                              >
                                {item.reIcon}
                              </TimelineDot>
                              {index !== items.length - 1 && (
                                <TimelineConnector
                                  sx={{
                                    height: "60px",
                                    backgroundColor: "#72757c",
                                  }}
                                />
                              )}
                            </TimelineSeparator>
                            <TimelineContent
                              sx={{ m: "3.5px 0" }}
                              variant="h6"
                              color={"#E8E8E8"}
                            >
                              {item.title}
                            </TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </Timeline>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
}
