import axios from "axios";
import { useEffect } from "react";
import { hasValue } from "../../utils/commonFns";
import { useSnackbar } from "notistack";

const AxiosInterceptor = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    axios.interceptors.request.use(
      function (config) {
        // spinning start to show
        // UPDATE: Add this code to show global loading indicator
        if (!config.url.endsWith("SendEmail")) {
          if (!hasValue(window.apiCounter)) window.apiCounter = 0;
          window.apiCounter += 1;
        }
        let user = window.localStorage.getItem("currentUser");
        if (user && !config.url.toString().includes("s3.amazonaws.com")) {
          user = JSON.parse(user);
          config.headers.Authorization = `Bearer ${user.token}`;
        }
        if (!config.headers["Content-Type"]) {
          config.headers["Content-Type"] = "application/json";
          config.headers.Accept = "application/json";
        }

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        if (!response.config.url.endsWith("SendEmail")) {
          window.apiCounter -= 1;
        }

        if (!response.data.success && hasValue(response.data.error)) {
          enqueueSnackbar(`Error - ${JSON.stringify(response.data.error)}`, {
            variant: "error",
            autoHideDuration: 7000,
            hideIconVariant: true,
          });
        }
        return response;
      },
      function (error) {
        if (!error.config.url.endsWith("SendEmail")) {
          window.apiCounter -= 1;
        }

        if (
          error?.response?.data?.error &&
          typeof error?.response?.data?.error === "string"
        ) {
          enqueueSnackbar(`${error?.response?.data?.error}`, {
            variant: "error",
            autoHideDuration: 7000,
            hideIconVariant: true,
          });
        } else {
          const errors = Object.values(
            error?.response?.data?.errors || {}
          )?.join("\n\n");
          enqueueSnackbar(
            `Error - ${error.message}\n${error?.response?.data?.title}\n${errors}`,
            {
              variant: "error",
              autoHideDuration: 7000,
              hideIconVariant: true,
            }
          );
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.request.clear();
      axios.interceptors.response.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default AxiosInterceptor;
