import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { DataGrid } from "@mui/x-data-grid";
import { Card, CardBody, Button, Row, Col, Label, Input } from "reactstrap";
import { useParams } from "react-router-dom";
import {
  getGapsByRemediationID,
  getRemediation,
  updateRemediationGapStatus,
} from "../../services/remediationService";
// import { useAuthState } from "../../context/login";
import RemediationResponseModal from "./RemediationResponseModal";
import { faFilePen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hasValue } from "../../utils/commonFns";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../utils/confirmationPopup";

export default function RemediationDetails() {
  const colDefs = [
    {
      field: "domainName",
      headerName: "Domain",
      width: 150,
    },
    {
      field: "issueIdentified",
      headerName: "Issue Identified",
      width: 290,
    },
    {
      field: "issueSeverity",
      headerName: "Severity",
      width: 85,
    },
    {
      field: "risk",
      headerName: "Risk",
      width: 290,
    },
    {
      field: "remediation",
      headerName: "Remediation",
      width: 290,
    },
    {
      field: "evidence",
      headerName: "Evidence",
      width: 290,
    },
    {
      field: "displayStatus",
      headerName: "Status",
      width: 110,
    },
    {
      field: "id",
      headerName: "Action",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <FontAwesomeIcon
            onClick={() =>
              setRemediationGapEvidenceId(params.row.remediationGapId)
            }
            tabIndex={-1}
            icon={faFilePen}
            fontSize={20}
            cursor={"pointer"}
            className="remediation-evidence-icon"
          />
        );
      },
    },
  ];

  const queryParams = useParams();
  const remediationId = queryParams["id"];
  let navigate = useNavigate();

  const [remediationGapEvidenceId, setRemediationGapEvidenceId] =
    useState(null);

  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);

  const [selectedGapIds, setSelectedGapIds] = useState([]);

  const [remediationGaps, setRemediationGaps] = useState([]);
  const [remediationData, setRemediationData] = useState({});

  const loadRemediationGaps = async () => {
    const { result } = await getGapsByRemediationID(remediationId);
    setRemediationGaps(result);
  };
  const loadRemediations = async () => {
    const { result } = await getRemediation(remediationId);
    setRemediationData(result);
  };

  useEffect(() => {
    loadRemediationGaps();
    loadRemediations();
  }, []);

  const remediationResponseModalHandle = (isSave) => {
    setRemediationGapEvidenceId(null);
    if (isSave) {
      loadRemediationGaps();
    }
  };

  const handleConfirmationPopup = async (isContinue) => {
    setIsConfirmationPopupOpen(false);

    if (isContinue) {
      const { result } = await updateRemediationGapStatus({
        RemediationGapIds: selectedGapIds,
        RemediationGapStatus: "SubmittedToBeaconer",
      });

      if (result) {
        toast("Submitted to Beaconer Successfully", {
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/remediations");
      }
    }
  };

  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition
          component="div"
          className="TabsAnimation"
          appear={true}
          timeout={0}
          enter={false}
          exit={false}
        >
          <div>
            <Card className="main-card mb-3">
              <CardBody>
                <div style={{ display: "flex", marginBottom: "8px" }}>
                  <div style={{ marginLeft: "auto" }}>
                    <Button
                      color="primary"
                      className="ms-3 mb-2"
                      onClick={() => {
                        if (selectedGapIds?.length <= 0) {
                          toast.error("Please select gaps to submit");
                          return;
                        }
                        setIsConfirmationPopupOpen(true);
                      }}
                    >
                      Submit to Beaconer
                    </Button>
                  </div>
                </div>

                {hasValue(remediationData.remarksForVendor) && (
                  <Row className="mb-4">
                    <Col md={6}>
                      <Label
                        required
                        for="remarks"
                        className="left-align col-form-label"
                      >
                        Remarks
                      </Label>
                      <Col>
                        <Input
                          name="remarks"
                          id="remarks"
                          value={remediationData.remarksForVendor}
                          readOnly
                        />
                      </Col>
                    </Col>
                  </Row>
                )}

                <div className="vendor-exchange-grid-container">
                  <DataGrid
                    rows={remediationGaps}
                    columns={colDefs}
                    pageSize={20}
                    checkboxSelection
                    disableSelectionOnClick
                    density="compact"
                    getRowId={(row) => row.remediationGapId}
                    sx={{
                      "& .MuiDataGrid-cellContent": {
                        whiteSpace: "normal",
                        lineHeight: "normal",
                        padding: "8px",
                      },
                    }}
                    getRowHeight={() => "auto"}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setSelectedGapIds(newRowSelectionModel);
                    }}
                    rowSelectionModel={selectedGapIds}
                  />
                </div>
              </CardBody>
            </Card>

            {hasValue(remediationGapEvidenceId) && (
              <RemediationResponseModal
                {...{
                  remediationGapEvidenceId,
                  remediationResponseModalHandle,
                }}
              />
            )}
            <ConfirmationPopup
              isOpen={isConfirmationPopupOpen}
              handleConfirmationPopup={handleConfirmationPopup}
              bodyText="Are you sure, do you want to Submit?"
            />
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
}
