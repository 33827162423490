import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import { useAuthState } from "../../../context/login";
import { getRecentAssessments } from "../../../services/tprmSupportService";
import { ZammadTicketStatesMap } from "../../../utils/commonFns";
import { TableContainer } from "@mui/material";
import TableRowsLoader from "src/v2/components/Table/TableRowsLoader";
import classes from "../styles.module.scss";

export default function AllAssessments() {
  const [assessments, setAssessments] = useState([]);
  const [assessmentLoading, setAssessmentLoading] = useState(false);

  const { user } = useAuthState();

  useEffect(() => {
    const fetchRecentAssessments = async () => {
      try {
        setAssessmentLoading(true);
        const { result } = await getRecentAssessments(
          user.vendorId,
          true,
          null
        );
        setAssessments(result);
      } finally {
        setAssessmentLoading(false);
      }
    };

    fetchRecentAssessments();
  }, []);

  let navigate = useNavigate();

  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition
          component="div"
          className="TabsAnimation"
          appear={true}
          timeout={0}
          enter={false}
          exit={false}
        >
          <div>
            <Grid>
              <Card sx={{ height: "100%" }}>
                <CardHeader title="All Assessments" />
                <Divider />
                <Box sx={{ overflowX: "auto" }}>
                  <TableContainer
                    sx={{ maxHeight: "calc(100vh - 203px)" }}
                    className={classes.table}
                  >
                    <Table
                      sx={{ minWidth: 800 }}
                      stickyHeader
                      className={classes.tablehead}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Assessment Number</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell sortDirection="desc">Start Date</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tablebody}>
                        {assessmentLoading ? (
                          <>
                            <TableRowsLoader rows={2} columns={4} />
                          </>
                        ) : (
                          assessments.map((order) => {
                            const { label, color } = ZammadTicketStatesMap[
                              order.status
                            ] ?? {
                              label: order.status,
                              color: "info",
                            };

                            return (
                              <TableRow
                                hover
                                key={order.ticketNo}
                                onClick={() =>
                                  navigate(`/assessment-details/${order.id}`)
                                }
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell sx={{ color: "#5885e6 !important" }}>
                                  {order.ticketNo}
                                </TableCell>
                                <TableCell>
                                  {order.businessOrganizationName}
                                </TableCell>
                                <TableCell>
                                  {dayjs(order.startDate).format("MMM D, YYYY")}
                                </TableCell>
                                <TableCell className={classes[`${color}-col`]}>
                                  {label}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Card>
            </Grid>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
}
