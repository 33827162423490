import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { DataGrid } from "@mui/x-data-grid";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import { getRemediations } from "../../services/remediationService";
import { useAuthState } from "../../context/login";
import { useNavigate } from "react-router-dom";

export default function Remediations() {
  const colDefs = [
    {
      field: "businessOrgName",
      headerName: "Client",
      width: 200,
      renderCell: (params) => {
        return (
          <a
            style={{
              textDecoration: "underline !important",
              color: "#545cd8",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(`/remediation/${params.row.remediationID}`);
            }}
          >
            {params.value}
          </a>
        );
      },
    },
    // {
    //   field: "remediationStatus",
    //   headerName: "Status",
    //   width: 250,
    // },
    {
      field: "initiatedOn",
      headerName: "Initiated On",
      width: 200,
      renderCell: (params) => {
        return <>{moment(params.value).format("MMM DD, YYYY")}</>;
      },
    },
  ];

  let navigate = useNavigate();

  const { user } = useAuthState();

  useEffect(() => {
    loadRemediations();
  }, []);

  const [remediations, setRemediations] = useState([]);

  const loadRemediations = async () => {
    const { result } = await getRemediations(user.vendorId);
    setRemediations(result);
  };

  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition
          component="div"
          className="TabsAnimation"
          appear={true}
          timeout={0}
          enter={false}
          exit={false}
        >
          <div>
            <Card className="main-card mb-3">
              <CardBody>
                <div className="vendor-exchange-grid-container">
                  <DataGrid
                    rows={remediations}
                    columns={colDefs}
                    pageSize={20}
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                    density="compact"
                    getRowId={(row) => row.remediationID}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
}
